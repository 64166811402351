body {
  width: 100%;
  padding: 0 15px;
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  list-style: none;
}

.f-carousel {
  max-width: 600px;
  margin: 0 auto;
}

.f-carousel__slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.f-carousel__slide img {
  width: 100%;
  height: auto;
}
