.layout {
    max-width: 960px; 
    margin: 0 auto; 
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.header {
    flex-shrink: 0;
}

.main {
    flex-grow: 1;
}

.footer {
    flex-shrink: 0;
    min-height: 50px;
}

@media screen and (min-width: 768px) {
    .layout {
        max-width: 740px; 
    }
}

@media screen and (min-width: 1100px) {
    .layout {
        max-width: 1060px; 
    }
}