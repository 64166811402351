.link {
  display: inline-block;
  text-decoration: none;
  padding: 12px;
  font-weight: 700;
  color: #2a363b;
}

.link.active {
  color: #e84a5f;
}

.mobileLinkBlock {
  margin-top: 50px;
  max-width: 140px;
  display: flex;
  flex-direction: column;
}

.linkBlock {
  display: flex;
}
